body{
  background-color: transparent;
}
a{
  text-decoration: none;
}
.idwapper{
  position: relative;
}
ul li{
  list-style: none;
}
.bottom_tab {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1099;
  background: #fff;
  padding: 5px 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.id_app{
  max-width: 460px;
  position: relative;
  margin: 0 auto;
}
.bottom_tab ul{
  justify-content: space-between;
}
.bgwapper{
  background: url('./images/ripple.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  min-height: 220px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}
.tot_wapps{
  position: relative;
  padding: 20px;
  background-color: #1A1A40;
  min-height: calc(100vh - 50px);
}
.cover_photo{
  position: relative;
  z-index: 1;
}
.cover_photo {
  position: relative;
  top: 20px;
}
.cover_photo img{
  width: 100%;
  border-radius: 12px;
  border: 1px solid #0075FE;
}
.social_icons ul li a{
  color: #fff;
  font-size: 28px;
}
.social_icons{
  padding-top: 60px;
  padding-bottom: 70px;
}
.social_icons ul{
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    width: 65%;
    margin: 0 auto;
}
.main-wapper{
  background-color: #fff;
  padding: 0 20px 20px;
  border-radius: 20px;
  margin-bottom: 70px;
}
.person-wapid{
  position: relative;
  top: -20px;
  display: flex;
  align-items: end;
}
.imgwap{
  width: 110px;
}
.imgwap img{
  width: 100%;
  border-radius: 10px;
}
.per_name{
  width: calc(100% - 110px);
  padding-left: 15px;
}
.per_name h2{
  font-weight: 700;
  font-size: 22px;
}
.per_name p{
  color: #424242;
  font-weight: 500;
  font-size: 12px;
}

.tele_lists .link_btn{
  background-color: #0354DC;
  color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  box-shadow: #004CCC 0px 4px 11px 0px;
}
.tele_lists{
  margin-bottom: 28px;
}
.listof_icons{
  border: 1px solid #424242;
  padding: 10px;
  border-radius: 15px;
}
.listof_icons ul{
  display: flex;
  padding: 0;
  margin-bottom: 0;
}
.listof_icons ul li {
	list-style: none;
	width: 33.33%;
	text-align: center;
  position: relative;
}
.listof_icons ul li:after{
  content: '';
  position: absolute;
  width: 1px;
  height: 50px;
  background: #424242;
  right: 0;
  top: 0;
}
.listof_icons ul li a {
	color: #424242;
	font-size: 14px;
}
.listof_icons ul li a span{
  display: block;
}

.listof_icons ul li:last-child::after{
  content: none;
}
.listof_icons ul li a svg{
  font-size: 24px;
}
 
.bottom_tab ul {
	justify-content: space-between;
	padding: 1px 10px;
  border-bottom: 0;

}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  border: 0;
}
  .bottom_tab ul svg {
    font-size: 22px;
    color: #424242;
}

.bottom_tab .nav-link, .nav-tabs .nav-link.active svg{
  color: #43A5FF;
}

/*page 2*/
.abtsec{
  background-color: #fff;
  padding: 15px 15px 40px;
  border-radius: 12px;
  z-index: 1;
  position: relative;
  margin-bottom: 70px;
}
.heads_side{
    color: #424242;
    font-weight: 700;
    padding-bottom: 12px;
}
.heads_side::after {
	content: '';
	position: absolute;
	background: #0a79b3;
	width: 50px;
	height: 4px;
	top: 14px;
	margin-left: 8px;
}
.abtwapp{
  font-size: 15px;
  color: #424242;
  overflow-y: auto;
  height: 200px;
  background: #EDF1F9;
  padding: 12px;
  border-radius: 10px;
}
.abtme{
  margin-bottom: 20px;
  position: relative;
}
.abt_logos{
  position: relative;
}
.img_logo img{
  width: 150px;
}
.abt_pages{
  padding-top: 12px;
}
.abt_pages p{
  color: #424242;
  font-size: 15px;
  padding-bottom: 8px;
}
.videos_lg{
  position: relative;
}
/*page 3*/

.join_newsletter{
  background-color: #EDF1F9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.join_newsletter h5{
  font-weight: 600;
  font-size: 20px;
}
.join_newsletter p{
  font-size: 15px;
}
.abtsec form{
  position: relative;
}
.abtsec form input{
  height: 50px;
  border-radius: 50px;
  padding-right: 60px;
  font-size: 13px;
}
.abtsec form input:focus{
  box-shadow: none;
  border: 0;
}
.buttoncls{
  background: #044BBB;
  color: #fff;
  border-radius: 20px;
  padding: 12px 20px;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
}
.aww-img{
  padding: 10px;
}
.aww-img img{
  width: 100%;
}
.News_awards,.blog_lists{
  position: relative;
}
.blog_lists{
  margin-top: 30px;
}

.fulllist ul li {
	background: #fff;
	width: 48%;
	display: inline-block;
	margin-right: 5px;
	border-radius: 10px;
	margin-bottom: 14px;
	box-shadow: 0 4px 8px 0 rgb(239 236 236 / 20%), 0 6px 20px 0 rgb(107 103 103 / 19%);
	padding: 5px;
}
.fulllist ul li img {
	width: 100%;
	border-radius: 10px;
}
.fulllist h6 {
  color: #424242;
  padding: 10px 0px;
  text-align: center;
  font-weight: 500;
  min-height: 70px;
  height: 70px;
  overflow: hidden;
}
.enq {
	text-align: center;
	padding: 5px 10px 10px;
}
.fulllist ul li a {
	background: #004AA1;
	color: #fff;
	padding: 5px 16px;
	border-radius: 5px;
	/* margin-bottom: 5px; */
	display: block;
}
.fulllist ul{
  padding-left: 0;
}
.slick-prev {
	left: -14px;
}
.slick-prev::before, .slick-next::before {
  color: #000;
}
.slick-next {
	right: -15px;
}
.video_sli{
  padding: 8px;
}
.bottom_tab ul li{
  position: relative;
}

.bottom_tab .nav-tabs .nav-link.active::after {
	content: '';
	position: absolute;
	background: #43A5FF;
	top: -6px;
	width: 100%;
	height: 5px;
	left: 0;
	right: 0;
	text-align: center;
}
.sharefoll {
	background-color: #EDF1F9;
	padding: 20px;
	border-radius: 20px;
  margin-bottom: 30px;
}
.white_label{
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
}
.white_label img{
  width: 180px;
}
.followus p {
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 5px;
}
.followus{
  padding: 20px 20px 0px;
}
.followus .social_icons{
  padding: 0;
}
.followus .social_icons ul li a{
  color: #000040;
}
.share_lists{
  position: relative;
}
.rowshares ul{
  background: #EEF2F3;
  display: flex;
  justify-content: space-between;
  border-bottom: 0;
  margin-bottom: 20px;
  border-radius: 25px;
  padding: 8px;
}
.rowshares ul li{

}
.rowshares ul li button{
    font-weight: 600;
    font-size: 16px;
    color: #000;
}
.rowshares .nav-tabs .nav-link.active{
  border-radius: 20px;

} 
.email_sends{
  display: flex;
  padding: 10px;
}
.email_sends input{
  margin-right: 12px;
  height: 42px;
}
.email_sends input:focus{
  box-shadow: none;
}
.email_sends a{
  background-color: #ed1e3a;
  border-color: #ed1e3a;
  padding: 9px 24px;
}
.qrscannersli {
	display: flex;
	align-items: center;
}
.lftscanners {
	width: 50%;
}
.lftscanners img {
	width: 100%;
}
.rtscanners {
	width: 50%;
}
.rtscanners h5 {
	color: #fff;
	background-color: #ed1e3a;
	border-color: #ed1e3a;
	padding: 11px 26px;
	margin: 0 auto;
	width: 95%;
	font-size: 12px;
	border-radius: 5px;
}
.rtscanners img {
	width: 100%;
}
.rowshares{
  border: 1px solid #7D7D7D;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 50px;
}
.power_lis{
  text-align: center;
}
.power_lis p{
  font-weight: 600;
}

.power_lis p span{
background: linear-gradient(to right, #6683E1 0%, #9E46FF 55%);
  background-clip: border-box;
background-clip: border-box;
background-clip: border-box;
background-clip: border-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-top: 12px;
}

.followus .social_icons ul
{
  width: 100%;
}












.mobilever{
  display: none;
} 


@media (max-width: 460px){
  .mobilever{
    display: block;
  }
}

@media (max-width: 420px){
  .bgwapper{
    min-height: 200px;
  }
}
@media (max-width: 380px){
  .bgwapper{
    min-height: 190px;
  }
  .per_name h2{
    font-size: 20px;
  }
}